import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store'
import { GridSortModel } from '@mui/x-data-grid-pro'
type CustomRange = {
  fromdate: string
  todate: string
}
type GridData = {
  defaultSortModel?: GridSortModel
  expandAllRows?: boolean
}
type IBalancesSliceState = {
  DateRange: string
  CustomDateRange: CustomRange
  gridData: GridData
}

const initialState: IBalancesSliceState = {
  DateRange: 'Prior12Months',
  CustomDateRange: {
    fromdate: '',
    todate: ''
  },
  gridData: {
    defaultSortModel: [{ field: 'totalaccountvalue', sort: 'desc' }],
    expandAllRows: false
  }
}

const Slice = createSlice({
  name: '@module/accounts/balances',
  initialState,
  reducers: {
    SetHistoricalDateRange: (state, action: PayloadAction<string>) => {
      return { ...state, DateRange: action.payload }
    },
    SetHistoricalCustomDateRange: (state, action: PayloadAction<CustomRange>) => {
      return { ...state, CustomDateRange: action.payload }
    },
    SetBalanceGridData: (state, action: PayloadAction<GridData | undefined>) => {
      return { ...state, gridData: { ...state.gridData, ...action.payload } }
    }
  }
})

export const { SetHistoricalDateRange, SetHistoricalCustomDateRange, SetBalanceGridData } = Slice.actions
export const getHistoricalDateRange = (state: RootState) => state.modules.accounts.balances.DateRange
export const getHistoricalCustomDateRange = (state: RootState) => state.modules.accounts.balances.CustomDateRange
export const getGridData = (state: RootState) => state.modules.accounts.balances.gridData
export default Slice.reducer
