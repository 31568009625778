import { CacheLookupPolicy } from '@azure/msal-browser'
import { Logger } from '../appinsights/Logger'
import { MsalInstance } from './MsalInstance'

export class MsalToken {
  static async getAccessToken(scopes: string[]) {
    const msalInstance = MsalInstance.getInstance()
    const account = msalInstance.getAllAccounts()?.[0]
    try {
      return await msalInstance.acquireTokenSilent({ scopes, account })
    } catch {
      //check if account exist to use RefreshTokenAndNetwork
      if (account) {
        Logger.LogInfo({ message: 'Fetching Token from network using refresh token' })
        Logger.LogInfo({ message: `Accounts : ${account?.username} access token requested from network` })
        return await msalInstance.acquireTokenSilent({
          scopes,
          cacheLookupPolicy: CacheLookupPolicy.RefreshTokenAndNetwork,
          account,
          forceRefresh: true
        })
      } else {
        Logger.LogInfo({ message: 'No Account exist hence logging user out to select account' })
        await msalInstance.logoutRedirect()
        return { tokenType: '', accessToken: '' }
      }
    }
  }
}
