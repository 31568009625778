import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { rockefeller } from '../system/environment/Config.json'
import { RootState } from '../store'

const fetchBasePaymentAuthApiQuery = fetchBaseQuery({
  baseUrl: `${rockefeller.api.baseurl}/registration/api/auth`,
  prepareHeaders: (headers: Headers, api) => {
    const state = api.getState() as RootState
    headers.append('Content-Type', 'application/json; charset=UTF-8;')
    headers.append('cache-control', 'no-cache')
    headers.append('regkey', state.modules.registration.registrationKey ?? '')
    if (rockefeller.api.version) {
      headers.append('version', rockefeller.api.version)
    }
    return headers
  }
})

const fetchBasePaymentAuthApiQueryWithRetry = retry(fetchBasePaymentAuthApiQuery, {
  maxRetries: 0
})

const PaymentAuthBaseApiSlice = createApi({
  reducerPath: '@api/paymentauth',
  baseQuery: fetchBasePaymentAuthApiQueryWithRetry,
  endpoints: () => ({}),
  keepUnusedDataFor: 0
})

export default PaymentAuthBaseApiSlice
