import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CollaborationValueEntity } from '../api/CollaborationList.types'
import { RootState } from '../../../store'
import { fileWithUploadStatus } from '../components/UploadFileDialog'
import { breadcrum } from '../Collaboration'

interface ICollaborationSliceState {
  selectedCollaborationDrive?: CollaborationValueEntity
  CollaborationsCount: number
  uploadFiles: fileWithUploadStatus[] | undefined
  breadcrums: breadcrum[] | undefined
}

const initialState: ICollaborationSliceState = {
  selectedCollaborationDrive: undefined,
  CollaborationsCount: 0,
  uploadFiles: [],
  breadcrums: []
}

const Slice = createSlice({
  name: '@module/collaborations',
  initialState,
  reducers: {
    SetSelectedCollaborationDrive: (state, action: PayloadAction<CollaborationValueEntity | undefined>) => {
      return { ...state, selectedCollaborationDrive: action.payload }
    },
    SetBreadCrum: (state, action: PayloadAction<breadcrum[] | undefined>) => {
      return { ...state, breadcrums: action.payload }
    },
    SetCollaborationsCount: (state, action: PayloadAction<number>) => {
      return { ...state, CollaborationsCount: action.payload }
    },
    SetUploadFiles: (state, action: PayloadAction<fileWithUploadStatus[]>) => {
      return { ...state, uploadFiles: action.payload }
    }
  }
})
export const { SetSelectedCollaborationDrive, SetCollaborationsCount, SetUploadFiles, SetBreadCrum } = Slice.actions
export const getSelectedCollaborationDrive = (state: RootState) =>
  state.modules.collaborations.selectedCollaborationDrive
export const getBreadcrums = (state: RootState) => state.modules.collaborations.breadcrums
export const getCollaborationsCount = (state: RootState) => state.modules.collaborations.CollaborationsCount
export const getUploadFiles = (state: RootState) => state.modules.collaborations.uploadFiles
export default Slice.reducer
