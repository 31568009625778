import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { WidgetSequence } from '../api/types'
import { DateRangeSelectedItem } from '../../accounts/activities/api/types'
import { format } from 'date-fns'

interface IActivitiesSummaryState {
  selectedPeriod: DateRangeSelectedItem
}
interface IGainLossSummaryState {
  selectedYear: string
}
interface IHistoricalSummaryState {
  selectedPeriod: DateRangeSelectedItem
}

interface IIncomeState {
  activeChartType?: boolean
  isIncludePremium?: boolean
  isIncludePremiumVisible: boolean
}
interface IInvestmentState {
  selectedInvestmentOption?: string
}

interface ISummaryState {
  isAccountSelectorCollapsed: boolean
  activities: IActivitiesSummaryState
  historical: IHistoricalSummaryState
  income: IIncomeState
  widgetOrder: WidgetSequence[]
  investment: IInvestmentState
  viewByIRROrTWR: boolean
  performanceWidgetDropdownState: string
  gainLoss: IGainLossSummaryState
}

const initialState: ISummaryState = {
  isAccountSelectorCollapsed: true,
  activities: {
    selectedPeriod: {
      value: 'LAST10DAYS'
    }
  },
  historical: {
    selectedPeriod: {
      value: 'Prior12Months'
    }
  },
  income: {
    activeChartType: false,
    isIncludePremiumVisible: false,
    isIncludePremium: true
  },
  widgetOrder: [],
  investment: {
    selectedInvestmentOption: 'AdvisorManaged'
  },
  viewByIRROrTWR: false,
  performanceWidgetDropdownState: 'Summary',
  gainLoss: {
    selectedYear: format(new Date(), 'yyyy')
  }
}

const Slice = createSlice({
  name: '@module/summary',
  initialState,
  reducers: {
    SetAccountSelectorCollapseState: (state, action: PayloadAction<boolean>) => {
      return { ...state, isAccountSelectorCollapsed: action.payload }
    },
    SetActivitiesPeriod: (state, action: PayloadAction<DateRangeSelectedItem>) => {
      return { ...state, activities: { ...state.activities, selectedPeriod: action.payload } }
    },
    SetHistoricalPeriod: (state, action: PayloadAction<DateRangeSelectedItem>) => {
      return { ...state, historical: { ...state.historical, selectedPeriod: action.payload } }
    },

    SetIncomeActiveChartType: (state, action: PayloadAction<boolean>) => {
      return { ...state, income: { ...state.income, activeChartType: action.payload } }
    },
    SetIncomeIncludePremiumState: (state, action: PayloadAction<boolean>) => {
      return { ...state, income: { ...state.income, isIncludePremium: action.payload } }
    },
    SetIncomeIncludePremiumVisibleState: (state, action: PayloadAction<boolean>) => {
      return { ...state, income: { ...state.income, isIncludePremiumVisible: action.payload } }
    },
    SetWidgetOrderState: (state, action: PayloadAction<WidgetSequence[]>) => ({
      ...state,
      widgetOrder: action.payload
    }),
    SetInvestementSelectedOptionState: (state, action: PayloadAction<string>) => {
      return { ...state, investment: { selectedInvestmentOption: action.payload } }
    },
    SetViewByTWRIRRState: (state, action: PayloadAction<boolean>) => {
      return { ...state, viewByIRROrTWR: action.payload }
    },
    SetPerformanceWidgetDropdownState: (state, action: PayloadAction<string>) => {
      return { ...state, performanceWidgetDropdownState: action.payload }
    },
    setGainLossSummarySelectedYear: (state, action: PayloadAction<string>) => {
      return { ...state, gainLoss: { selectedYear: action.payload } }
    }
  }
})

export const {
  SetAccountSelectorCollapseState,
  SetActivitiesPeriod,
  SetHistoricalPeriod,
  SetIncomeActiveChartType,
  SetIncomeIncludePremiumState,
  SetIncomeIncludePremiumVisibleState,
  SetWidgetOrderState,
  SetInvestementSelectedOptionState,
  SetViewByTWRIRRState,
  SetPerformanceWidgetDropdownState,
  setGainLossSummarySelectedYear
} = Slice.actions
export const getViewByTWRIRRState = (state: RootState) => state.modules.summary.viewByIRROrTWR
export const getPerformanceWidgetDropdownState = (state: RootState) =>
  state.modules.summary.performanceWidgetDropdownState
export const getAccountSelectorCollapseState = (state: RootState) => state.modules.summary.isAccountSelectorCollapsed
export const getActivitiesPeriodState = (state: RootState) => state.modules.summary.activities.selectedPeriod
export const getHistoricalPeriodState = (state: RootState) => state.modules.summary.historical.selectedPeriod
export const getIncomeActiveChartType = (state: RootState) => state.modules.summary.income.activeChartType
export const getIncomeIncludePremiumState = (state: RootState) => state.modules.summary.income.isIncludePremium
export const getIncomeIncludePremiumVisiableState = (state: RootState) =>
  state.modules.summary.income.isIncludePremiumVisible
export const getWidgetOrderState = (state: RootState) => state.modules.summary.widgetOrder
export const getInvestementSelectedOptionState = (state: RootState) =>
  state.modules.summary.investment.selectedInvestmentOption
export const getGainLossSummarySelectedYearState = (state: RootState) => state.modules.summary.gainLoss.selectedYear
export default Slice.reducer
