import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { TotalnetworthAccounts } from '../../../modules/home/api/profile.types'
import { MyProfileContactInfo } from '../../../modules/myprofile/myprofile/api/api/types'

interface IMyProfileSliceState {
  allAccounts?: TotalnetworthAccounts[]
  totalNetworthAccounts?: TotalnetworthAccounts[]
  isAllAccountSelected: string
  myProfileContactDetails?: MyProfileContactInfo
}

const initialState: IMyProfileSliceState = {
  totalNetworthAccounts: [],
  isAllAccountSelected: 'false'
}

const Slice = createSlice({
  name: '@module/myprofile',
  initialState,
  reducers: {
    SetSelectedTotalNetWorthAccounts: (state, action: PayloadAction<TotalnetworthAccounts[]>) => {
      return { ...state, totalNetworthAccounts: action.payload }
    },
    SetTotalNetWorthAllAccountSelected: (state, action: PayloadAction<string>) => {
      return { ...state, isAllAccountSelected: action.payload }
    }
  }
})

export const { SetSelectedTotalNetWorthAccounts, SetTotalNetWorthAllAccountSelected } = Slice.actions
export const getTotalNetWorthAccountsSelector = (state: RootState) =>
  state?.modules?.myProfile?.totalNetworthAccounts || []
export const getAllTotalNetWorthAccountsSelectedSelector = (state: RootState) =>
  state.modules.myProfile.isAllAccountSelected

export default Slice.reducer
