import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../../store'
import { GridRowId } from '@mui/x-data-grid-pro'
import { DateRangeSelectedItem } from '../../../api/types'

type StatementsFilter = {
  statementsType?: string
  dateRange?: DateRangeSelectedItem
  showAllAccounts?: boolean
}
export type SelectedStatements = {
  objectId: string | undefined
  primaryValue: string | undefined
  date: string | undefined
  id: GridRowId
  DownloadFileName?: string | null
}
interface IStatementsSliceState {
  statementsFilter: StatementsFilter
  selectedStatements: SelectedStatements[]
  totalStatements: number
}

const initialState: IStatementsSliceState = {
  statementsFilter: {
    dateRange: { value: 'Last60days' },
    statementsType: 'detail',
    showAllAccounts: false
  },
  selectedStatements: [],

  totalStatements: 0
}

const Slice = createSlice({
  name: '@module/accounts/documents/statements',
  initialState,
  reducers: {
    SetStatementsFilter: (state, action: PayloadAction<StatementsFilter>) => {
      return { ...state, statementsFilter: { ...action.payload } }
    },
    SetSelectedStatements: (state, action: PayloadAction<SelectedStatements[]>) => {
      return { ...state, selectedStatements: action.payload }
    },
    SetTotalStatementsCount: (state, action: PayloadAction<number>) => {
      return { ...state, totalStatements: action.payload }
    }
  }
})

export const { SetStatementsFilter, SetSelectedStatements, SetTotalStatementsCount } = Slice.actions
export const getStatementsFilterState = (state: RootState) =>
  state.modules.accounts.documents.statements.statementsFilter
export const getSelectedStatements = (state: RootState) =>
  state.modules.accounts.documents.statements.selectedStatements
export const getTotalStatementsCount = (state: RootState) => state.modules.accounts.documents.statements.totalStatements
export default Slice.reducer
