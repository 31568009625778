import { combineReducers } from '@reduxjs/toolkit'
import ActivitesReducer from '../activities/store/ActivitiesSlice'
import DocumentsReducer from '../documents/store/DocumentReducer'
import IncomesReducer from '../incomes/store/IncomesSlice'
import InvestmentsReducer from '../investments/store/InvestmentsSlice'
import GainLossReducer from '../gainloss/store/GainLossSlice'
import BalancesReducer from '../balances/store/BalancesSlice'
import OtherAssetsAndLiabilitiesReducer from '../otherassetsandliabilities/store/OtherAssetsAndLiabilitiesSlice'
const AccountsReducer = combineReducers({
  activities: ActivitesReducer,
  balances: BalancesReducer,
  investments: InvestmentsReducer,
  incomes: IncomesReducer,
  gainloss: GainLossReducer,
  documents: DocumentsReducer,
  otherAssetsAndLiabilities: OtherAssetsAndLiabilitiesReducer
})

export default AccountsReducer
