import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../store'
import { rockefeller } from '../system/environment/Config.json'
import { MsalToken } from '../system/msal/MsalToken'

const fetchBaseRockefellerApiQuery = fetchBaseQuery({
  baseUrl: rockefeller.api.baseurl,
  prepareHeaders: async (headers: Headers, api) => {
    const tokenResult = await MsalToken.getAccessToken([rockefeller.api.scope])
    const state = api.getState() as RootState
    headers.append('Authorization', `${tokenResult.tokenType} ${tokenResult.accessToken}`)
    headers.append('Content-Type', 'application/json; charset=UTF-8;')
    headers.append('cache-control', 'no-cache')
    headers.append('profilejson', state.app.profileKey ?? '')
    headers.append('contextjson', state.app.contextKey ?? '')
    if (rockefeller.api.version) {
      headers.append('version', rockefeller.api.version)
    }
    return headers
  }
})

const RockefellerApiSlice = createApi({
  reducerPath: '@api/rockefeller',
  baseQuery: fetchBaseRockefellerApiQuery,
  endpoints: () => ({}),
  keepUnusedDataFor: 60 * 60,
  tagTypes: ['Refresh']
})

export default RockefellerApiSlice
