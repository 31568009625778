import { EventMessage, Logger, PublicClientApplication, EventType } from '@azure/msal-browser'
import { GetMsalConfiguration } from './MsalConfig'
import { MsalLogger } from './MsalLogger'
import { Logger as AppInsightLogger } from '../appinsights/Logger'
import { removeSessionStorage } from '../../shared/utilities/Session'
import { BrowserSessionKey } from '../../shared/Constants'

export class MsalInstance {
  private static instance: PublicClientApplication
  constructor() {
    if (MsalInstance.instance) {
      throw new Error('Error - use MsalInstance.getInstance()')
    }
  }

  static getInstance(isSimulation?: boolean): PublicClientApplication {
    if (!MsalInstance.instance) {
      AppInsightLogger.LogInfo({ message: 'New MSAL Instance Created' })
      const msalInstance = new PublicClientApplication(GetMsalConfiguration(isSimulation ?? false))
      msalInstance.setLogger(
        new Logger({
          piiLoggingEnabled: false,
          loggerCallback: MsalLogger
        })
      )

      //Setting active account
      msalInstance.addEventCallback((message: EventMessage) => {
        if (message.eventType === EventType.LOGIN_SUCCESS) {
          const accounts = msalInstance.getAllAccounts()
          if (accounts.length > 0) {
            AppInsightLogger.LogInfo({ message: `Setting active account ${accounts[0].username}` })
            msalInstance.setActiveAccount(accounts[0])
          }
        }

        if (message.eventType === EventType.ACCOUNT_REMOVED) {
          AppInsightLogger.LogInfo({ message: 'User active account removed' })
        }

        if (message.eventType === EventType.LOGIN_FAILURE) {
          AppInsightLogger.LogInfo({ message: `Login failed isSimulation: ${isSimulation}` })
        }

        if (message.eventType === EventType.LOGOUT_SUCCESS) {
          AppInsightLogger.LogInfo({ message: 'Logout end clearning browser session key' })
          removeSessionStorage(BrowserSessionKey)
        }

        if (message.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
          AppInsightLogger.LogInfo({ message: 'Acquire token failed.' })
        }
      })

      MsalInstance.instance = msalInstance
    }
    return MsalInstance.instance
  }
}
