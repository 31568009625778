import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../../store'
import { GridRowId } from '@mui/x-data-grid-pro'
import { DateRangeSelectedItem } from '../../../api/types'

type CorrespondenceFilter = {
  dateRange?: DateRangeSelectedItem | undefined
  showAllAccounts?: boolean
}
export type SelectedCorrespondence = {
  objectId: string | undefined
  primaryValue: string | undefined
  date: string | undefined
  id: GridRowId
}
interface ICorrespondenceSliceState {
  correspondenceFilter: CorrespondenceFilter
  selectedCorrespondence: SelectedCorrespondence[]
  totalCorrespondence: number
}

const initialState: ICorrespondenceSliceState = {
  correspondenceFilter: {
    dateRange: { value: 'Last30days' },
    showAllAccounts: false
  },
  selectedCorrespondence: [],

  totalCorrespondence: 0
}

const Slice = createSlice({
  name: '@module/accounts/documents/correspondence',
  initialState,
  reducers: {
    SetCorrespondenceFilter: (state, action: PayloadAction<CorrespondenceFilter>) => {
      return { ...state, correspondenceFilter: { ...action.payload } }
    },
    SetSelectedCorrespondence: (state, action: PayloadAction<SelectedCorrespondence[]>) => {
      return { ...state, selectedCorrespondence: action.payload }
    },
    SetTotalCorrespondenceCount: (state, action: PayloadAction<number>) => {
      return { ...state, totalCorrespondence: action.payload }
    }
  }
})

export const { SetCorrespondenceFilter, SetSelectedCorrespondence, SetTotalCorrespondenceCount } = Slice.actions
export const getCorrespondenceFilterState = (state: RootState) =>
  state.modules.accounts.documents.correspondence.correspondenceFilter
export const getSelectedCorrespondence = (state: RootState) =>
  state.modules.accounts.documents.correspondence.selectedCorrespondence
export const getTotalCorrespondenceCount = (state: RootState) =>
  state.modules.accounts.documents.correspondence.totalCorrespondence
export default Slice.reducer
