import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { graph } from '../system/environment/Config.json'

const fetchBaseGraphApiQuery = fetchBaseQuery({
  baseUrl: graph.api.baseurl,
  prepareHeaders: async (headers: Headers) => {
    headers.append('Content-Type', 'application/json')
    headers.append('cache-control', 'no-cache')
    return headers
  }
})

const GraphApiSlice = createApi({
  reducerPath: '@api/graph',
  baseQuery: fetchBaseGraphApiQuery,
  endpoints: () => ({}),
  tagTypes: ['CollaborationItemList']
})

export default GraphApiSlice
