import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../store'

interface ISliceState {
  lastRefreshDt: string
}

const initialState: ISliceState = {
  lastRefreshDt: new Date().toString()
}

const Slice = createSlice({
  name: '@shared/rcmtabmenu',
  initialState,
  reducers: {
    SetLastRefreshDateTime: (state) => {
      return { ...state, lastRefreshDt: new Date().toString() }
    }
  }
})

export const { SetLastRefreshDateTime } = Slice.actions
export const getLastRefreshDateTime = (state: RootState) => state.shared.rcmTabMenu.lastRefreshDt
export default Slice.reducer
