import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { rockefeller } from '../../system/environment/Config.json'

const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    connectionString: rockefeller.telemetry.connection,
    enableAutoRouteTracking: true,
    disableCookiesUsage: true,
    extensions: [reactPlugin]
  }
})
appInsights.addTelemetryInitializer((envelope) => {
  if (envelope?.tags) {
    envelope.tags['ai.cloud.role'] = 'Rockefeller Digital'
    envelope.tags['ai.cloud.roleInstance'] = 'Rockefeller Digital'
  }
})
appInsights.loadAppInsights()
export { reactPlugin, appInsights }
