import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../../store'
import { GridRowId } from '@mui/x-data-grid-pro'
import { DateRangeSelectedItem } from '../../../api/types'

type TrustStatementsFilter = {
  dateRange?: DateRangeSelectedItem
  showAllAccounts?: boolean
}
export type SelectedTrustStatements = {
  id: GridRowId
  docid?: string | null
  docname?: string
}
interface ITrustStatementsSliceState {
  trustStatementsFilter: TrustStatementsFilter
  selectedTrustStatements: SelectedTrustStatements[]
  totalTrustStatements: number
}
const initialState: ITrustStatementsSliceState = {
  trustStatementsFilter: {
    dateRange: { value: 'Last30days' }
  },
  selectedTrustStatements: [],
  totalTrustStatements: 0
}

const Slice = createSlice({
  name: '@module/accounts/documents/trustStatements',
  initialState,
  reducers: {
    SetTrustStatementsFilter: (state, action: PayloadAction<TrustStatementsFilter>) => {
      return { ...state, trustStatementsFilter: { ...action.payload } }
    },
    SetSelectedTrustStatements: (state, action: PayloadAction<SelectedTrustStatements[]>) => {
      return { ...state, selectedTrustStatements: action.payload }
    },
    SetTotalTrustStatementsCount: (state, action: PayloadAction<number>) => {
      return { ...state, totalTrustStatements: action.payload }
    }
  }
})

export const { SetTrustStatementsFilter, SetSelectedTrustStatements, SetTotalTrustStatementsCount } = Slice.actions
export const getTrustStatementsFilterState = (state: RootState) =>
  state.modules.accounts.documents.trustStatements.trustStatementsFilter
export const getTotalTrustStatementsCount = (state: RootState) =>
  state.modules.accounts.documents.trustStatements.totalTrustStatements
export const getSelectedTrustStatements = (state: RootState) =>
  state.modules.accounts.documents.trustStatements.selectedTrustStatements
export default Slice.reducer
