import { ILoggerCallback, LogLevel } from '@azure/msal-browser'
import { Logger } from '../appinsights/Logger'

export const MsalLogger: ILoggerCallback = (level: LogLevel, message: string, containPii: boolean) => {
  if (containPii) {
    return
  } else if (level === LogLevel.Error) {
    Logger.LogException({ message, exceptionType: 'MSAL ERROR' })
  } else {
    Logger.LogInfo({ message })
  }
}
