import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { format } from 'date-fns'
import { RootState } from '../../../../store'

type GainlossFilter = {
  yearRange: string
}
interface IGainLossSliceState {
  gainlossFilter: GainlossFilter
}

const initialState: IGainLossSliceState = {
  gainlossFilter: {
    yearRange: format(new Date(), 'yyyy')
  }
}

const Slice = createSlice({
  name: '@module/accounts/GainLosses',
  initialState,
  reducers: {
    SetGainlossFilter: (state, action: PayloadAction<GainlossFilter>) => {
      return { ...state, gainlossFilter: { ...action.payload } }
    }
  }
})

export const { SetGainlossFilter } = Slice.actions
export const getGainLossFilterState = (state: RootState) => state.modules.accounts.gainloss.gainlossFilter
export default Slice.reducer
