import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../../store'

interface IQuickLinkState {
  lastShortcutItemClickDt: string
}

const initialState: IQuickLinkState = {
  lastShortcutItemClickDt: new Date().toString()
}

const Slice = createSlice({
  name: '@shared/quicklinks',
  initialState,
  reducers: {
    SetLastShortCutClickDt: (state) => {
      return { ...state, lastShortcutItemClickDt: new Date().toString() }
    }
  }
})

export const { SetLastShortCutClickDt } = Slice.actions
export const getLastShortCutClickDt = (state: RootState) => state.shared.quickLinks.lastShortcutItemClickDt
export default Slice.reducer
