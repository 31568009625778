import { Routes, Route } from 'react-router-dom'
import React from 'react'
import ErrorBoundary from './shared/components/errorboundary/ErrorBoundary'
import { OAuthCallBackRoute } from './modules/accounts/externalaccounts/components/connect/shared/Constants'
const PaymentAuthConatiner = React.lazy(() => import('./modules/external/payments/PaymentAuthContainer'))
const ConnectExternalAccountOAuthCallback = React.lazy(
  () => import('./modules/accounts/externalaccounts/components/connect/oauth/ConnectExternalAccountOAuthCallback')
)
const Timeout = React.lazy(() => import('./modules/login/Timeout'))
const Login = React.lazy(() => import('./modules/login/Login'))
const Register = React.lazy(() => import('./modules/registration/RegistrationContainer'))
const ClientV1Redirect = React.lazy(() => import('./modules/login/clientv1/RedirectClientV1'))

const AppPublicRoutes: React.FC = () => {
  return (
    <ErrorBoundary message="Page failed to load, please refresh your browser to try again.">
      <React.Suspense fallback={<></>}>
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/timeout" element={<Timeout />} />
          <Route path="/clientv1redirect" element={<ClientV1Redirect />} />
          <Route path={`${OAuthCallBackRoute}/:id`} element={<ConnectExternalAccountOAuthCallback />} />
          <Route path="/atz/*" element={<PaymentAuthConatiner />}>
            <Route path=":id" element={<PaymentAuthConatiner />} />
          </Route>
          <Route path="*" element={<Login />} />
        </Routes>
      </React.Suspense>
    </ErrorBoundary>
  )
}

export { AppPublicRoutes }
