import { DateRanges } from '../../../../../shared/Constants'

export const ActivityOptions = [
  {
    label: 'Buys',
    value: 'BY'
  },
  {
    label: 'Sells',
    value: 'SL'
  },
  {
    label: 'Dividends & Interest',
    value: 'DV'
  },
  {
    label: 'Fees & Interest Charged',
    value: 'FE'
  },
  {
    label: 'Deposits / Contributions',
    value: 'DP'
  },
  {
    label: 'Withdrawals',
    value: 'WD'
  },
  {
    label: 'Journal Entries',
    value: 'JN'
  },
  {
    label: 'Corporate Actions',
    value: 'CA'
  },
  {
    label: 'Exchanges / Transfers',
    value: 'EX'
  },
  {
    label: 'Other',
    value: 'ZZ'
  }
]

export const DateRangeOptions = [
  {
    label: 'Today',
    value: 'TODAY'
  },
  {
    label: 'Prior Day',
    value: 'PRIORDAY'
  },
  {
    label: 'Last 10 Days',
    value: 'LAST10DAYS'
  },
  {
    label: DateRanges.LAST30DAYS.label,
    value: 'LAST30DAYS'
  },
  {
    label: 'Current Year',
    value: DateRanges.YEARTODATE.value
  },
  {
    label: 'Previous Year',
    value: DateRanges.PRIORYEAR.value
  },
  {
    label: DateRanges.CUSTOMRANGE.label,
    value: DateRanges.CUSTOMRANGE.value
  }
]
