import { Routes, Route } from 'react-router-dom'
import React from 'react'
import ErrorBoundary from './shared/components/errorboundary/ErrorBoundary'
const AccountVerification = React.lazy(() => import('./modules/login/verification/AccountVerification'))
const UpdateRegistration = React.lazy(() => import('./modules/registration/updateregistration/UpdateRegistration'))

const AppUnverifiedRoutes: React.FC = () => {
  return (
    <ErrorBoundary message="Page failed to load, please refresh your browser to try again.">
      <React.Suspense fallback={<></>}>
        <Routes>
          <Route path="/register" element={<UpdateRegistration />} />
          <Route path="*" element={<AccountVerification />} />
        </Routes>
      </React.Suspense>
    </ErrorBoundary>
  )
}

export { AppUnverifiedRoutes }
