import { Account, GroupedAccount, Groups, IProfile } from '../api/profile.types'

export const UpdateNickNameByKey = (accounts: Account[], key: string, value: string) => {
  const clonedAccounts = [...accounts]
  const index = clonedAccounts.findIndex((account) => account.key === key)
  if (index > -1) {
    const existingAccount = accounts[index]
    clonedAccounts.splice(index, 1, { ...existingAccount, nickname: value ?? '' })
  }
  return clonedAccounts
}

export const UpdateNickNameInGroupedAccounts = (groupedAccounts: GroupedAccount[], key: string, value: string) => {
  const clonedGroupedAccounts = [...groupedAccounts]
  const updatedGrpAccts = clonedGroupedAccounts.map((grpAcct) => {
    const accounts = grpAcct.accounts ?? []
    const updatedAccts = UpdateNickNameByKey(accounts, key, value)
    return { ...grpAcct, accounts: [...updatedAccts] }
  })
  return updatedGrpAccts
}

export const UpdateNickNameInGroups = (groups: Groups, key: string, value: string) => {
  const clonedGroupedAccounts = [...(groups.customgroups || [])]
  const clonedDefaultAccounts = [...(groups.defaultgroups || [])]
  const updatedGrpAccts = UpdateNickNameInGroupedAccounts(clonedGroupedAccounts, key, value)
  const updatedDafaultAccts = UpdateNickNameInGroupedAccounts(clonedDefaultAccounts, key, value)
  return { customgroups: updatedGrpAccts, defaultgroups: updatedDafaultAccts }
}

export const UpdateNickNameInProfile = (profile: IProfile, key: string, value: string) => {
  const clonedProfile = { ...profile }
  const groups = { ...clonedProfile.groups }
  const updatedGroups = UpdateNickNameInGroups(groups, key, value)
  const updatedProfile = {
    ...profile,
    groups: { ...updatedGroups }
  }
  return updatedProfile
}

export const RemoveAccountByKey = (accounts: Account[], key: string) => {
  const clonedAccounts = [...accounts]
  const index = clonedAccounts.findIndex((account) => account.key === key)
  if (index > -1) {
    clonedAccounts.splice(index, 1)
  }
  return clonedAccounts
}

export const RemoveAccountFromGroupedAccounts = (groupedAccounts: GroupedAccount[], key: string) => {
  const clonedGroupedAccounts = [...groupedAccounts]
  const updatedGrpAccts = clonedGroupedAccounts.map((grpAcct) => {
    const accounts = grpAcct.accounts ?? []
    const updatedAccts = RemoveAccountByKey(accounts, key)
    return { ...grpAcct, accounts: [...updatedAccts] }
  })
  return updatedGrpAccts
}
export const RemoveAccountFromGroups = (groups: Groups, key: string) => {
  const clonedGroupedAccounts = [...(groups.customgroups || [])]
  const clonedDefaultAccounts = [...(groups.defaultgroups || [])]
  const updatedGrpAccts = RemoveAccountFromGroupedAccounts(clonedGroupedAccounts, key)
  const updatedDafaultAccts = RemoveAccountFromGroupedAccounts(clonedDefaultAccounts, key)
  return { customgroups: updatedGrpAccts, defaultgroups: updatedDafaultAccts }
}
export const AccountRemoveFromProfile = (profile: IProfile, key: string, source: string) => {
  const clonedProfile = { ...profile }
  const groups = { ...clonedProfile.groups }
  const updatedGroups = RemoveAccountFromGroups(groups, key)
  const updatedProfile = {
    ...profile,
    groups: { ...updatedGroups },
    totalaccounts: GetUpdatedTotals(source, profile, 'remove')
  }

  return updatedProfile
}

export const GetUpdatedTotals = (
  source: string,
  profile: IProfile,
  mode: string,
  noOfAccountsAddedOrRemoved: number | undefined = 1
) => {
  const numberofgroupedaccounts =
    profile?.totalaccounts?.numberofgroupedaccounts &&
    (mode === 'add'
      ? profile?.totalaccounts?.numberofgroupedaccounts + noOfAccountsAddedOrRemoved
      : profile?.totalaccounts?.numberofgroupedaccounts - noOfAccountsAddedOrRemoved)
  const numberofprofileaccounts =
    profile?.totalaccounts?.numberofprofileaccounts &&
    (mode === 'add'
      ? profile?.totalaccounts?.numberofprofileaccounts + noOfAccountsAddedOrRemoved
      : profile?.totalaccounts?.numberofprofileaccounts - noOfAccountsAddedOrRemoved)
  const numberofexternalaccounts =
    source === 'external' && profile?.totalaccounts?.numberofexternalaccounts
      ? mode === 'add'
        ? profile?.totalaccounts?.numberofexternalaccounts + noOfAccountsAddedOrRemoved
        : profile?.totalaccounts?.numberofexternalaccounts - noOfAccountsAddedOrRemoved
      : profile?.totalaccounts?.numberofexternalaccounts
  const numberofotheraccounts =
    source === 'manual' && profile?.totalaccounts?.numberofotheraccounts
      ? mode === 'add'
        ? profile?.totalaccounts?.numberofotheraccounts + noOfAccountsAddedOrRemoved
        : profile?.totalaccounts?.numberofotheraccounts - noOfAccountsAddedOrRemoved
      : profile?.totalaccounts?.numberofotheraccounts
  const totalaccounts = {
    ...profile?.totalaccounts,
    numberofgroupedaccounts: numberofgroupedaccounts,
    numberofprofileaccounts: numberofprofileaccounts,
    numberofexternalaccounts: numberofexternalaccounts,
    numberofotheraccounts: numberofotheraccounts
  }
  return totalaccounts
}

export const UpdateGroupNameInGroupedAccounts = (groupedAccounts: GroupedAccount[], key: string, value: string) => {
  const clonedGroupedAccounts = [...groupedAccounts]
  const index = clonedGroupedAccounts.findIndex((group) => group.groupid === key)
  if (index > -1) {
    const existingGroup = groupedAccounts[index]
    clonedGroupedAccounts.splice(index, 1, { ...existingGroup, groupname: value ?? '' })
  }
  return clonedGroupedAccounts
}
export const UpdateGroupNameInProfile = (profile: IProfile, key: string, value: string) => {
  const clonedProfile = { ...profile }
  const clonedCustomGroups = clonedProfile?.groups?.customgroups || []
  const updatedCustomGroups = UpdateGroupNameInGroupedAccounts(clonedCustomGroups, key, value)

  const updatedGroups = { ...profile.groups, customgroups: updatedCustomGroups }
  const updatedProfile = {
    ...profile,
    groups: { ...updatedGroups }
  }
  return updatedProfile
}

export const RemoveAccountsFromGroupedAccounts = (groupedAccounts: GroupedAccount[], key: string[]) => {
  const clonedGroupedAccounts = [...groupedAccounts]
  const updatedGrpAccts = clonedGroupedAccounts.map((grpAcct) => {
    const accounts = grpAcct.accounts ?? []
    const updatedAccts = accounts.filter((account) => !key.includes(account.key))
    return { ...grpAcct, accounts: [...updatedAccts] }
  })
  return updatedGrpAccts
}
export const AccountsRemoveFromProfile = (profile: IProfile, keys: string[], source: string) => {
  const clonedProfile = { ...profile }
  const clonedCustomGroups = clonedProfile?.groups?.customgroups || []
  const updatedCustomGroups = RemoveAccountsFromGroupedAccounts(clonedCustomGroups, keys)
  const clonedDefaultGroups = clonedProfile?.groups?.defaultgroups || []
  const updatedDefaultGroups = RemoveAccountsFromGroupedAccounts(clonedDefaultGroups, keys)
  const updatedGroups = { ...profile.groups, customgroups: updatedCustomGroups, defaultgroups: updatedDefaultGroups }

  const updatedProfile = {
    ...profile,
    groups: { ...updatedGroups },
    totalaccounts: GetUpdatedTotals(source, profile, 'remove', keys.length)
  }
  return updatedProfile
}
