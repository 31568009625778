import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store'
import { TotalNetWorth } from './types'

interface ITotalNetWorthState {
  totalNetWorthCollection?: TotalNetWorth
  isAccordionExpand: boolean
  isPopupOpen: boolean
}

const initialState: ITotalNetWorthState = {
  isAccordionExpand: true,
  isPopupOpen: false
}

const Slice = createSlice({
  name: '@shared/totalnetworth',
  initialState,
  reducers: {
    SetTotalNetWorthState: (state, action: PayloadAction<TotalNetWorth>) => {
      return { ...state, totalNetWorthCollection: action.payload }
    },
    SetAcordionExpandState: (state, action: PayloadAction<boolean>) => {
      return { ...state, isAccordionExpand: action.payload }
    }
  }
})

export const { SetTotalNetWorthState, SetAcordionExpandState } = Slice.actions
export const getAccordionExpandState = (state: RootState) => state.shared.totalNetWorth.isAccordionExpand
export const getPopupOpenState = (state: RootState) => state.shared.totalNetWorth.isPopupOpen
export default Slice.reducer
