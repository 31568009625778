import { combineReducers } from '@reduxjs/toolkit'
import AppReducer from './slice'
import RockefellerApiSlice from '../api/RockefellerApiSlice'
import GraphApiSlice from '../api/GraphApiSlice'
import { modulesReducer } from '../modules/store/ModulesReducers'
import sharedReducer from '../shared/components/store/sharedReducer'
import RockefellerNonContextApiSlice from '../api/RockefellerNonContextApiSlice'
import RegistrationBaseApiSlice from '../api/RegistrationBaseApiSlice'
import PaymentAuthBaseApiSlice from '../api/PaymentAuthBaseApiSlice'

const rootReducer = combineReducers({
  [RockefellerApiSlice.reducerPath]: RockefellerApiSlice.reducer,
  [RockefellerNonContextApiSlice.reducerPath]: RockefellerNonContextApiSlice.reducer,
  [GraphApiSlice.reducerPath]: GraphApiSlice.reducer,
  [RegistrationBaseApiSlice.reducerPath]: RegistrationBaseApiSlice.reducer,
  [PaymentAuthBaseApiSlice.reducerPath]: PaymentAuthBaseApiSlice.reducer,
  app: AppReducer,
  modules: modulesReducer,
  shared: sharedReducer
})

export default rootReducer
