import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store'
import { ActivityOptions } from '../components/filter/Constants'
import { DateRangeSelectedItem } from '../api/types'

type ActivityFilter = {
  activityType: string
  dateRange: DateRangeSelectedItem
}
interface IActivitySliceState {
  activitiesFilter: ActivityFilter
}

const initialState: IActivitySliceState = {
  activitiesFilter: {
    activityType: ActivityOptions.map((x) => x.value).join(','),
    dateRange: {
      value: 'LAST10DAYS'
    }
  }
}

const Slice = createSlice({
  name: '@module/accounts/activities',
  initialState,
  reducers: {
    SetActivitiesFilter: (state, action: PayloadAction<ActivityFilter>) => {
      return { ...state, activitiesFilter: { ...action.payload } }
    }
  }
})

export const { SetActivitiesFilter } = Slice.actions
export const getActivitiesFilterState = (state: RootState) => state.modules.accounts.activities.activitiesFilter
export default Slice.reducer
