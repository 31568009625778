import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../store'

interface ILoginSliceState {
  userEmail?: string
}

const initialState: ILoginSliceState = {}

const Slice = createSlice({
  name: '@module/login',
  initialState,
  reducers: {
    SetUserEmail: (state, action: PayloadAction<string | undefined>) => {
      return { ...state, userEmail: action.payload }
    }
  }
})

export const { SetUserEmail } = Slice.actions
export const getUserEmail = (state: RootState) => state.modules.login.userEmail
export default Slice.reducer
