import { GridRowId } from '@mui/x-data-grid-pro'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../../store'
import { DateRangeSelectedItem } from '../../../api/types'

type TradeConfirmationsFilter = {
  dateRange?: DateRangeSelectedItem
  showAllAccounts?: boolean
}
export type SelectedTradeConfirmations = {
  objectId: string | undefined
  primaryValue: string | undefined
  date: string | undefined
  id: GridRowId
}
interface ITradeConfirmationsSliceState {
  tradeConfirmationsFilter: TradeConfirmationsFilter
  selectedTradeConfirmations: SelectedTradeConfirmations[]
  totalTradeConfirmations: number
}

const initialState: ITradeConfirmationsSliceState = {
  tradeConfirmationsFilter: {
    dateRange: { value: 'Last7Days' },
    showAllAccounts: false
  },
  selectedTradeConfirmations: [],

  totalTradeConfirmations: 0
}

const Slice = createSlice({
  name: '@module/accounts/documents/tradeConfirmations',
  initialState,
  reducers: {
    SetTradeConfirmationsFilter: (state, action: PayloadAction<TradeConfirmationsFilter>) => {
      return { ...state, tradeConfirmationsFilter: { ...action.payload } }
    },
    SetSelectedTradeConfirmations: (state, action: PayloadAction<SelectedTradeConfirmations[]>) => {
      return { ...state, selectedTradeConfirmations: action.payload }
    },
    SetTotalTradeConfirmationsCount: (state, action: PayloadAction<number>) => {
      return { ...state, totalTradeConfirmations: action.payload }
    }
  }
})

export const { SetTradeConfirmationsFilter, SetSelectedTradeConfirmations, SetTotalTradeConfirmationsCount } =
  Slice.actions
export const getTradeConfirmationsFilterState = (state: RootState) =>
  state.modules.accounts.documents.tradeConfirmations.tradeConfirmationsFilter
export const getSelectedTradeConfirmations = (state: RootState) =>
  state.modules.accounts.documents.tradeConfirmations.selectedTradeConfirmations
export const getTotalTradeConfirmationsCount = (state: RootState) =>
  state.modules.accounts.documents.tradeConfirmations.totalTradeConfirmations
export default Slice.reducer
