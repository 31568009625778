import { ICustomProperties, ITelemetryItem } from '@microsoft/applicationinsights-web'
import { BrowserSessionKey, UserTypedEmailKey } from '../../shared/Constants'
import { getSessionStorage } from '../../shared/utilities/Session'
import { appInsights } from './AppInsights'

interface ILogInfoParams {
  message?: string
  customProperties?: ICustomProperties
}

interface ILogExceptionPrams {
  exceptionType: string
  message?: string
  customProperties?: ICustomProperties
}

export class Logger {
  private static telemetryInitializer = (envelope: ITelemetryItem) => {
    const browserSessionKey = getSessionStorage(BrowserSessionKey)
    if (envelope.tags) {
      envelope.tags['ai.user.id'] = getSessionStorage(UserTypedEmailKey) ?? ''
      envelope.tags['ai.user.authUserId'] = getSessionStorage(UserTypedEmailKey) ?? ''
      if (browserSessionKey) {
        envelope.tags['ai.session.id'] = browserSessionKey
      }
    }
  }

  static readonly LogInfo = ({ message, customProperties }: ILogInfoParams) => {
    if (message) {
      appInsights.clearAuthenticatedUserContext()
      appInsights.addTelemetryInitializer(this.telemetryInitializer)
      appInsights.trackTrace(
        {
          message
        },
        customProperties
      )
    }
  }

  static readonly LogException = ({ message, exceptionType, customProperties }: ILogExceptionPrams) => {
    appInsights.clearAuthenticatedUserContext()
    appInsights.addTelemetryInitializer(this.telemetryInitializer)
    appInsights.trackException({ exception: new Error(message), id: exceptionType }, customProperties)
  }
}
