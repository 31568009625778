import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { StrategicInsight, StrategicInsightResponse } from '../components/api/types'

interface IStrategicInsightsSliceState {
  articles: StrategicInsight[]
  totalArticles: number
  nextPageKey: number
  hasMore: boolean
}

const initialState: IStrategicInsightsSliceState = {
  articles: [],
  totalArticles: 0,
  nextPageKey: 0,
  hasMore: false
}

const Slice = createSlice({
  name: '@module/strategic-insights',
  initialState,
  reducers: {
    SetArticles: (state, action: PayloadAction<StrategicInsightResponse>) => {
      return {
        ...state,
        articles: [...state.articles, ...(action.payload.perspectives ?? [])],
        totalArticles: action.payload.totalInsights ?? 0,
        nextPageKey: action.payload.nextPageKey ?? 0,
        hasMore: action.payload.hasMore ?? false
      }
    },
    ClearArticles: (state) => {
      return {
        ...state,
        ...initialState
      }
    }
  }
})

export const { SetArticles, ClearArticles } = Slice.actions
export const getStrategicInsights = (state: RootState) => state.modules.strategicInsights.articles
export const getTotalStrategicInsights = (state: RootState) => state.modules.strategicInsights.totalArticles
export const getHasMoreInsights = (state: RootState) => state.modules.strategicInsights.hasMore
export const getStrategicInsightsNextPageKey = (state: RootState) => state.modules.strategicInsights.nextPageKey
export default Slice.reducer
