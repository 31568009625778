import React, { Component, ErrorInfo, ReactNode } from 'react'
import { Logger } from '../../../system/appinsights/Logger'
import { Box, Typography } from '@mui/material'

interface Props {
  children: ReactNode
  message?: string
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    try {
      Logger.LogException({
        message: `${JSON.stringify(error)} ${JSON.stringify(errorInfo)}`,
        exceptionType: 'UnexpectedError'
      })
    } catch {
      //do nothing
    }
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="h3">{this.props?.message ?? 'Something went wrong. Please try again later.'}</Typography>
        </Box>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
