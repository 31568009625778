import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AddAccountInSelectorRequest, SelectedAccount } from '../../../../modules/home/api/profile.types'
import { RootState } from '../../../../store'
import { AccountsBalance, ExpandedGroup } from '../api/types'

interface IAccountSelectorSliceState {
  selectedAccount?: SelectedAccount[]
  view: boolean
  deselectedAccount?: SelectedAccount[]
  accountsBalances?: AccountsBalance
  isBalanceLoading: boolean
  expandedGroup: ExpandedGroup
}

const initialState: IAccountSelectorSliceState = {
  view: false,
  isBalanceLoading: false,
  expandedGroup: { defaultView: 'Rockefeller Accounts' }
}

const Slice = createSlice({
  name: '@shared/accountSelector',
  initialState,
  reducers: {
    SetSelectedAccountSelector: (state, action: PayloadAction<SelectedAccount[]>) => {
      return { ...state, selectedAccount: action.payload }
    },
    SetAccountSelectorView: (state, action: PayloadAction<boolean>) => {
      return { ...state, view: action.payload }
    },
    SetDeselectedAccountSelector: (state, action: PayloadAction<SelectedAccount[]>) => {
      return { ...state, deselectedAccount: action.payload }
    },
    RemoveSelecedAccount: (state, action: PayloadAction<string>) => {
      const selectedAccounts = state.selectedAccount
      const key = action.payload
      if (selectedAccounts) {
        const clonedSelectedAccounts = [...selectedAccounts]
        const index = clonedSelectedAccounts.findIndex((x) => x.key === key)
        if (index > -1) {
          clonedSelectedAccounts.splice(index, 1)
          return { ...state, selectedAccount: [...clonedSelectedAccounts] }
        }
      }
      return { ...state }
    },
    RemoveSelectedAccounts: (state, action: PayloadAction<string[]>) => {
      const selectedAccounts = state.selectedAccount
      const keys = action.payload
      if (selectedAccounts) {
        const clonedSelectedAccountsFiltered = selectedAccounts.filter((x) => !keys.includes(x.key ?? ''))
        return { ...state, selectedAccount: [...clonedSelectedAccountsFiltered] }
      }
      return { ...state }
    },
    SetAccountsBalances: (state, action: PayloadAction<AccountsBalance>) => {
      return { ...state, accountsBalances: action.payload }
    },
    SetIsBalanceLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, isBalanceLoading: action.payload }
    },
    AddSelecedAccount: (state, action: PayloadAction<AddAccountInSelectorRequest>) => {
      const selectedAccounts = state.selectedAccount

      if (selectedAccounts && action.payload.mode === 'add' && action.payload.addedAccounts) {
        const addedAccounts = action.payload.addedAccounts.map((key) => ({ key: key, isActive: true }))
        const clonedSelectedAccounts = [...selectedAccounts]
        return { ...state, selectedAccount: [...clonedSelectedAccounts, ...addedAccounts] }
      }
      return { ...state }
    },
    SetExpandedGroup: (state, action: PayloadAction<string>) => {
      let expandedGroup: ExpandedGroup = state.expandedGroup
      if (state.view) {
        expandedGroup = { ...expandedGroup, customeView: action.payload }
      } else {
        expandedGroup = { ...expandedGroup, defaultView: action.payload }
      }
      return { ...state, expandedGroup: expandedGroup }
    }
  }
})

export const {
  SetSelectedAccountSelector,
  SetAccountSelectorView,
  SetDeselectedAccountSelector,
  RemoveSelecedAccount,
  RemoveSelectedAccounts,
  SetAccountsBalances,
  SetIsBalanceLoading,
  AddSelecedAccount,
  SetExpandedGroup
} = Slice.actions
export const getSelectedAccountSelector = (state: RootState) => state.shared.accountSelector.selectedAccount ?? []
export const getAccountSelectorView = (state: RootState) => state.shared.accountSelector.view
export const getDeselectedAccountSelector = createSelector(
  (state: RootState) => state.shared.accountSelector.deselectedAccount,
  (deselectedAccount) => deselectedAccount ?? []
)
export const getAccountsBalances = (state: RootState) => state.shared.accountSelector.accountsBalances
export const getIsBalanceLoading = (state: RootState) => state.shared.accountSelector.isBalanceLoading
export default Slice.reducer
export const getExpandedGroup = (state: RootState) => {
  if (state.shared.accountSelector.view) return state.shared.accountSelector.expandedGroup.customeView
  else return state.shared.accountSelector.expandedGroup.defaultView
}
