import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'

export const usePrint = <T extends HTMLElement>() => {
  const componentRef = useRef<T>(null)
  const onBeforeGetContentResolve = React.useRef<(() => void) | null>(null)
  const [isPrinting, setIsPrinting] = useState<boolean>(false)

  const printContent = React.useCallback(() => {
    return componentRef.current
  }, [])

  const handleOnBeforeGetContent = React.useCallback(() => {
    return new Promise<void>((resolve) => {
      onBeforeGetContentResolve.current = resolve
      setIsPrinting(true)
      resolve()
    })
  }, [])

  const handlePrint = useReactToPrint({
    content: printContent,
    onBeforeGetContent: handleOnBeforeGetContent,
    onAfterPrint: () => setIsPrinting(false)
  })

  useEffect(() => {
    if (isPrinting && typeof onBeforeGetContentResolve.current === 'function') {
      onBeforeGetContentResolve.current()
    }
  }, [isPrinting])

  return { componentRef, isPrinting, handlePrint }
}
