import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import React, { useMemo } from 'react'
import { IdleTimerProvider } from 'react-idle-timer'
import './App.css'
const Home = React.lazy(() => import('./modules/home/Home'))
import {
  AccountNotVerifiedTemplate,
  AccountVerifiedTemplate
} from './modules/login/template/AccountVerificationTemplates'
import { usePrint } from './shared/hooks/usePrint'
import rcmBlueTheme from './shared/themes/RcmBlue'
import { AppPublicRoutes } from './App.Public.Routes'
import { useAppSelector } from './store/hooks'
import { getSimulationReq } from './store/slice'
import { Logger } from './system/appinsights/Logger'
import { AppUnverifiedRoutes } from './App.UnVerified.Routes'

export type PrintContextType = {
  isPrinting: boolean
  print?: () => void
}
export const PrintContext = React.createContext<PrintContextType>({ isPrinting: false })

const App: React.FC = () => {
  const simulationReq = useAppSelector(getSimulationReq)
  const timeout = simulationReq ? 60 * 1000 * 60 : 60 * 1000 * 20
  const { instance: msalInstance, accounts: msalAccounts } = useMsal()
  const { componentRef, isPrinting, handlePrint } = usePrint<HTMLDivElement>()
  const onIdle = () => {
    Logger.LogInfo({ message: 'Application went idle, logout redirect initiated' })
    msalInstance.logoutRedirect({ account: msalAccounts?.[0], postLogoutRedirectUri: '/timeout' }).catch(() => {
      Logger.LogException({ message: 'Logout redirect failed for timeout', exceptionType: 'MSAL' })
    })
  }

  const printContextValue: PrintContextType = useMemo(
    () => ({ print: handlePrint, isPrinting: isPrinting }),
    [handlePrint, isPrinting]
  )

  return (
    <ThemeProvider theme={rcmBlueTheme}>
      <AuthenticatedTemplate>
        <IdleTimerProvider timeout={timeout} onIdle={onIdle}>
          <CssBaseline />
          <AccountVerifiedTemplate>
            <Box ref={componentRef} component="div">
              <PrintContext.Provider value={printContextValue}>
                <React.Suspense>
                  <Home />
                </React.Suspense>
              </PrintContext.Provider>
            </Box>
          </AccountVerifiedTemplate>
          <AccountNotVerifiedTemplate>
            <AppUnverifiedRoutes />
          </AccountNotVerifiedTemplate>
        </IdleTimerProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AppPublicRoutes />
      </UnauthenticatedTemplate>
    </ThemeProvider>
  )
}

export default App
