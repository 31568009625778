import { Logger } from '../../system/appinsights/Logger'

export function setSessionStorage(key: string, value: string) {
  if (typeof Storage !== 'undefined') {
    sessionStorage.setItem(key, value)
  } else {
    Logger.LogException({
      message: `Unable to set session storage for key ${key}`,
      exceptionType: 'UNSUPPORTED_SESSION_STORAGE'
    })
  }
}

export function getSessionStorage(key: string) {
  if (typeof Storage !== 'undefined') {
    return sessionStorage.getItem(key)
  } else {
    Logger.LogException({
      message: `Unable to set session storage for key ${key}`,
      exceptionType: 'UNSUPPORTED_SESSION_STORAGE'
    })
    return null
  }
}

export function removeSessionStorage(key: string) {
  if (typeof Storage !== 'undefined') {
    sessionStorage.removeItem(key)
  } else {
    Logger.LogException({
      message: `Unable to remove session storage for key ${key}`,
      exceptionType: 'UNSUPPORTED_SESSION_STORAGE'
    })
  }
}
