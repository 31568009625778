import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store'

type IncomesFilter = {
  period?: number
  type?: boolean
  accountSummaryPeriod?: number
  years?: string
  page?: string
}
interface IIncomeSliceState {
  incomesFilter: IncomesFilter
  isIncludePremium?: boolean
  isIncludePremiumVisible?: boolean
}

const initialState: IIncomeSliceState = {
  incomesFilter: {
    period: 2,
    type: true,
    years: new Date().getFullYear().toString(),
    accountSummaryPeriod: new Date().getFullYear(),
    page: 'summary'
  },
  isIncludePremium: true,
  isIncludePremiumVisible: false
}

const Slice = createSlice({
  name: '@module/accounts/incomes',
  initialState,
  reducers: {
    SetIncomesFilter: (state, action: PayloadAction<IncomesFilter>) => {
      return { ...state, incomesFilter: { ...action.payload } }
    },
    SetIncomeIncludePremium: (state, action: PayloadAction<boolean>) => {
      return { ...state, isIncludePremium: action.payload }
    },
    SetIncomeIncludePremiumVisibleState: (state, action: PayloadAction<boolean>) => {
      return { ...state, isIncludePremiumVisible: action.payload }
    }
  }
})

export const { SetIncomesFilter, SetIncomeIncludePremium, SetIncomeIncludePremiumVisibleState } = Slice.actions
export const getIncomeIncludePremium = (state: RootState) => state.modules.accounts.incomes.isIncludePremium
export const getIncomesFilterState = (state: RootState) => state.modules.accounts.incomes.incomesFilter
export const getIncomeIncludePremiumVisiableState = (state: RootState) =>
  state.modules.accounts.incomes.isIncludePremiumVisible
export default Slice.reducer
