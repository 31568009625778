import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../../store'
import { DateRangeSelectedItem, DocumentReportsListWithId } from '../../../api/types'

type PerformanceReportsFilter = {
  dateRange?: DateRangeSelectedItem
  showAllAccounts?: boolean
}
interface IPerformanceReportsSliceState {
  performanceReportsFilter: PerformanceReportsFilter
  selectedPerformanceReports: DocumentReportsListWithId[]
  totalPerformanceReports: number
}
const initialState: IPerformanceReportsSliceState = {
  performanceReportsFilter: {
    dateRange: { value: 'Last30days' }
  },
  selectedPerformanceReports: [],
  totalPerformanceReports: 0
}

const Slice = createSlice({
  name: '@module/accounts/documents/performanceReports',
  initialState,
  reducers: {
    SetPerformanceReportsFilter: (state, action: PayloadAction<PerformanceReportsFilter>) => {
      return { ...state, performanceReportsFilter: { ...action.payload } }
    },
    SetSelectedPerformanceReports: (state, action: PayloadAction<DocumentReportsListWithId[]>) => {
      return { ...state, selectedPerformanceReports: action.payload }
    },
    SetTotalPerformanceReportsCount: (state, action: PayloadAction<number>) => {
      return { ...state, totalPerformanceReports: action.payload }
    }
  }
})

export const { SetPerformanceReportsFilter, SetSelectedPerformanceReports, SetTotalPerformanceReportsCount } =
  Slice.actions
export const getPerformanceReportsFilterState = (state: RootState) =>
  state.modules.accounts.documents.performanceReports.performanceReportsFilter
export const getTotalPerformanceReportsCount = (state: RootState) =>
  state.modules.accounts.documents.performanceReports.totalPerformanceReports
export const getSelectedPerformanceReports = (state: RootState) =>
  state.modules.accounts.documents.performanceReports.selectedPerformanceReports
export default Slice.reducer
