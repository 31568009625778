import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store'
import { ManualAccount } from '../api/types'

interface IOtherAssetsSliceState {
  assetLiabilityType: string
  successMessage?: string
  errorMessage?: string
  selectedManualAccount?: ManualAccount
}

const initialState: IOtherAssetsSliceState = {
  assetLiabilityType: ''
}

const Slice = createSlice({
  name: '@module/accounts/otherAssetsAndLiabilities',
  initialState,
  reducers: {
    SetAssetLiabilityType: (state, action: PayloadAction<string>) => {
      return { ...state, assetLiabilityType: action.payload }
    },
    ResetOtherAssetsAndLiabilities: (state) => {
      return { ...state, assetLiabilityType: '' }
    },
    SetSuccessMessage: (state, action: PayloadAction<string>) => {
      return { ...state, successMessage: action.payload }
    },
    SetErrorMessage: (state, action: PayloadAction<string>) => {
      return { ...state, errorMessage: action.payload }
    },
    SetSelectedManualAccount: (state, action: PayloadAction<ManualAccount>) => {
      return { ...state, selectedManualAccount: action.payload }
    },
    ResetMessages: (state) => {
      return { ...state, successMessage: '', errorMessage: '' }
    }
  }
})

export const { SetAssetLiabilityType } = Slice.actions
export const { ResetOtherAssetsAndLiabilities } = Slice.actions
export const { SetSuccessMessage } = Slice.actions
export const { SetErrorMessage } = Slice.actions
export const { SetSelectedManualAccount } = Slice.actions
export const { ResetMessages } = Slice.actions

export const getAssetLiabilityType = (state: RootState) =>
  state.modules.accounts.otherAssetsAndLiabilities.assetLiabilityType
export const getSuccessMessage = (state: RootState) => state.modules.accounts.otherAssetsAndLiabilities.successMessage
export const getErrorMessage = (state: RootState) => state.modules.accounts.otherAssetsAndLiabilities.errorMessage
export const getSelectedManualAccount = (state: RootState) =>
  state.modules.accounts.otherAssetsAndLiabilities.selectedManualAccount

export default Slice.reducer
