import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PreferenceCollection, PreferenceRequest } from './types'
import { RootState } from '../../../store'

interface IPreferenceState {
  preferenceCollection?: PreferenceCollection
}

const initialState: IPreferenceState = {}

const Slice = createSlice({
  name: '@shared/prefereneces',
  initialState,
  reducers: {
    UpdatePreferenceStateByKey: (state, action: PayloadAction<PreferenceRequest>) => {
      const itemIndex =
        state.preferenceCollection?.preferencejson.component.findIndex((item) => item.key === action.payload.key) ?? -1
      if (itemIndex !== -1) {
        state.preferenceCollection?.preferencejson.component.splice(itemIndex, 1)
      }
      state.preferenceCollection?.preferencejson.component.push(action.payload)
    },
    SetPreferenceState: (state, action: PayloadAction<PreferenceCollection>) => {
      return { ...state, preferenceCollection: action.payload }
    }
  }
})

export const { SetPreferenceState, UpdatePreferenceStateByKey } = Slice.actions
export const getPreferenceState = (state: RootState) =>
  state.shared.preferences.preferenceCollection?.preferencejson?.component
export const getPilotFeaturesState = (state: RootState) =>
  state.shared.preferences?.preferenceCollection?.preferencejson?.pilotfeatures
export default Slice.reducer
