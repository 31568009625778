import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store'

interface IDocumnetSliceState {
  hideDetailViewAccountSelector?: boolean
}

const initialState: IDocumnetSliceState = {
  hideDetailViewAccountSelector: false
}

const Slice = createSlice({
  name: '@module/accounts/document',
  initialState,
  reducers: {
    SetHideDetailViewAccountSelector: (state, action: PayloadAction<boolean>) => {
      return { ...state, hideDetailViewAccountSelector: action.payload }
    }
  }
})

export const { SetHideDetailViewAccountSelector } = Slice.actions
export const getHideDetailViewAccountSelector = (state: RootState) =>
  state.modules.accounts.documents.document.hideDetailViewAccountSelector
export default Slice.reducer
