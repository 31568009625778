import { BrowserCacheLocation, Configuration } from '@azure/msal-browser'
import { msal } from '../environment/Config.json'

export const GetMsalConfiguration = (isSimulation: boolean): Configuration => {
  return {
    auth: {
      clientId: msal.clientid,
      authority: msal.authority,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: isSimulation ? BrowserCacheLocation.LocalStorage : BrowserCacheLocation.SessionStorage,
      secureCookies: false,
      storeAuthStateInCookie: false
    },
    system: {
      windowHashTimeout: 60000,
      iframeHashTimeout: 6000,
      loadFrameTimeout: 0
    },
    telemetry: {
      application: {
        appName: msal.appname,
        appVersion: msal.appversion
      }
    }
  }
}
