import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { rockefeller } from '../system/environment/Config.json'
import { RootState } from '../store'

const fetchBaseRegistrationApiQuery = fetchBaseQuery({
  baseUrl: rockefeller.api.baseurl,
  prepareHeaders: (headers: Headers, api) => {
    const state = api.getState() as RootState
    headers.append('Content-Type', 'application/json; charset=UTF-8;')
    headers.append('cache-control', 'no-cache')
    headers.append('regkey', state.modules.registration.registrationKey ?? '')
    if (rockefeller.api.version) {
      headers.append('version', rockefeller.api.version)
    }
    return headers
  }
})

const RegistrationBaseApiSlice = createApi({
  reducerPath: '@api/registration',
  baseQuery: fetchBaseRegistrationApiQuery,
  endpoints: () => ({}),
  keepUnusedDataFor: 60 * 60
})

export default RegistrationBaseApiSlice
