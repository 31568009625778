import { configureStore } from '@reduxjs/toolkit'
import GraphApiSlice from '../api/GraphApiSlice'
import RockefellerApiSlice from '../api/RockefellerApiSlice'
import RockefellerNonContextApiSlice from '../api/RockefellerNonContextApiSlice'
import rootReducer from './reducer'
import RegistrationBaseApiSlice from '../api/RegistrationBaseApiSlice'
import PaymentAuthBaseApiSlice from '../api/PaymentAuthBaseApiSlice'
import { devtools } from '../system/environment/Config.json'

const store = configureStore({
  reducer: rootReducer,
  devTools: devtools?.redux?.enabled,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(RockefellerApiSlice.middleware)
      .concat(GraphApiSlice.middleware)
      .concat(RockefellerNonContextApiSlice.middleware)
      .concat(RegistrationBaseApiSlice.middleware)
      .concat(PaymentAuthBaseApiSlice.middleware)
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
