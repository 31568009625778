import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store'
import { InvestmentsSummaryResponse } from '../api/types'

type IInvTotalNetValue = {
  totalValue: number | undefined
  totalChange: number | undefined | null
  isLoading: boolean
}
interface IInvestmentsSliceState {
  data?: InvestmentsSummaryResponse
  isError?: boolean
  isfetching?: boolean
  viewByInvDropDown?: string
  expandedRows?: { parent: string | undefined; id: string }[]
  invTotalNetValue?: IInvTotalNetValue
  expandedRowID?: string
  viewByAssetExpandedAssetCatCode?: string
  expandInvAccntL1RowFromBalance?: { key: string; type: string }
  expandInvAccntL2RowFromBalance?: { key: string; type: string }
}

const initialState: IInvestmentsSliceState = {
  viewByInvDropDown: 'Asset',
  expandedRows: [{ parent: '', id: '' }],
  invTotalNetValue: { totalValue: undefined, totalChange: undefined, isLoading: false }
}

const Slice = createSlice({
  name: '@module/accounts/investments',
  initialState,
  reducers: {
    SetViewByInvDropDown: (state, action: PayloadAction<string>) => {
      return { ...state, viewByInvDropDown: action.payload }
    },
    SetInvTotalNetValue: (state, action: PayloadAction<IInvTotalNetValue>) => {
      return { ...state, invTotalNetValue: action.payload }
    },
    SetViewByAccntExpandedRowsAfterSearch: (state, action) => {
      return { ...state, expandedRows: action.payload }
    },
    SetViewByInvestmentExpandedRowID: (state, action: PayloadAction<string>) => {
      return { ...state, expandedRowID: action.payload }
    },
    SetViewByAssetExpandedAssetCatCode: (state, action: PayloadAction<string>) => {
      return { ...state, viewByAssetExpandedAssetCatCode: action.payload }
    },
    SetInvExpandL2AccountRow: (state, action: PayloadAction<{ key: string; type: string }>) => {
      return { ...state, expandInvAccntL2RowFromBalance: action.payload }
    },
    SetInvExpandAccountRowFromBalance: (state, action: PayloadAction<{ key: string; type: string }>) => {
      return { ...state, expandInvAccntL1RowFromBalance: action.payload }
    }
  }
})
export const {
  SetViewByInvDropDown,
  SetViewByAccntExpandedRowsAfterSearch,
  SetInvTotalNetValue,
  SetViewByInvestmentExpandedRowID,
  SetViewByAssetExpandedAssetCatCode,
  SetInvExpandL2AccountRow,
  SetInvExpandAccountRowFromBalance
} = Slice.actions
export const getViewByInvDropDown = (state: RootState) => state.modules.accounts.investments.viewByInvDropDown
export const getInvTotalNetValue = (state: RootState) => state.modules.accounts.investments.invTotalNetValue
export const getViewByAccntExpandedRowsAfterSearch = (state: RootState) =>
  state.modules.accounts.investments.expandedRows
export const getViewByInvestmentExpandedRowID = (state: RootState) => state.modules.accounts.investments.expandedRowID
export const getViewByAssetExpandedAssetCatCode = (state: RootState) =>
  state.modules.accounts.investments.viewByAssetExpandedAssetCatCode
export const getInvExpandL2AccountRow = (state: RootState) =>
  state.modules.accounts.investments.expandInvAccntL2RowFromBalance
export const getInvExpandAccountRowFromBalance = (state: RootState) =>
  state.modules.accounts.investments.expandInvAccntL1RowFromBalance
export default Slice.reducer
