import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { StaticConfig } from './types'

interface IStaticConfigSliceState {
  staticConfigCollection?: StaticConfig
}

const initialState: IStaticConfigSliceState = {}

const Slice = createSlice({
  name: '@shared/staticConfig',
  initialState,
  reducers: {
    SetStaticConfigSelector: (state, action: PayloadAction<StaticConfig>) => {
      return { ...state, staticConfigCollection: action.payload }
    }
  }
})

export const { SetStaticConfigSelector } = Slice.actions
export const getStaticConfigSelector = (state: RootState) => state.shared.staticConfig.staticConfigCollection
export const getWireAccountInformationSelector = (state: RootState) =>
  state.shared.staticConfig.staticConfigCollection?.accountinformation?.Wire
export default Slice.reducer
