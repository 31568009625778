import React, { PropsWithChildren } from 'react'
import { useAppSelector } from '../../../store/hooks'
import { getIsAccountVerified } from '../../../store/slice'

const AccountVerifiedTemplate: React.FC<PropsWithChildren> = ({ children }) => {
  const isAccountVerified = useAppSelector(getIsAccountVerified)
  return <>{isAccountVerified && <>{children}</>}</>
}

const AccountNotVerifiedTemplate: React.FC<PropsWithChildren> = ({ children }) => {
  const isAccountVerified = useAppSelector(getIsAccountVerified)
  return <>{!isAccountVerified && <>{children}</>}</>
}

export { AccountVerifiedTemplate, AccountNotVerifiedTemplate }
