import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getYear } from 'date-fns'
import { RootState } from '../../../../../../store'
import { GridRowId } from '@mui/x-data-grid-pro'

type TaxDocumentsFilter = {
  dateRange?: dateRange
  showAllAccounts?: boolean
}
type dateRange = {
  fromdate: string
  todate: string
}
export type SelectedTaxDocuments = {
  objectId: string | undefined
  primaryValue: string | undefined
  date: string | undefined
  id: GridRowId
  streamId: string
  documentType?: string
}
interface ITaxDocumentsSliceState {
  taxDocumentsFilter: TaxDocumentsFilter
  selectedTaxDocuments: SelectedTaxDocuments[]
  totalTaxDocuments: number
}

const initialState: ITaxDocumentsSliceState = {
  taxDocumentsFilter: {
    dateRange: { fromdate: `01-01-${getYear(new Date()) - 1}`, todate: `12-31-${getYear(new Date()) - 1}` },
    showAllAccounts: false
  },
  selectedTaxDocuments: [],

  totalTaxDocuments: 0
}

const Slice = createSlice({
  name: '@module/accounts/documents/taxDocuments',
  initialState,
  reducers: {
    SetTaxDocumentsFilter: (state, action: PayloadAction<TaxDocumentsFilter>) => {
      return { ...state, taxDocumentsFilter: { ...action.payload } }
    },
    SetSelectedTaxDocuments: (state, action: PayloadAction<SelectedTaxDocuments[]>) => {
      return { ...state, selectedTaxDocuments: action.payload }
    },
    SetTotalTaxDocumentsCount: (state, action: PayloadAction<number>) => {
      return { ...state, totalTaxDocuments: action.payload }
    }
  }
})

export const { SetTaxDocumentsFilter, SetSelectedTaxDocuments, SetTotalTaxDocumentsCount } = Slice.actions
export const getTaxDocumentsFilterState = (state: RootState) =>
  state.modules.accounts.documents.taxDocuments.taxDocumentsFilter
export const getSelectedTaxDocuments = (state: RootState) =>
  state.modules.accounts.documents.taxDocuments.selectedTaxDocuments
export const getTotalTaxDocumentsCount = (state: RootState) =>
  state.modules.accounts.documents.taxDocuments.totalTaxDocuments
export default Slice.reducer
