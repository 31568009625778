import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '.'
import { SimulationRequestType } from './types'

interface ISliceState {
  isAccountVerified: boolean
  contextKey?: string
  profileKey?: string
  simulationReq?: SimulationRequestType | null
}

const initialState: ISliceState = {
  isAccountVerified: false
}

const Slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    SetAccountVerifiedStatus: (state, action: PayloadAction<boolean>) => {
      return { ...state, isAccountVerified: action.payload }
    },
    SetProfileContextKey: (state, action: PayloadAction<{ contextKey: string; profileKey: string }>) => {
      return { ...state, contextKey: action.payload.contextKey, profileKey: action.payload.profileKey }
    },
    SetSimulationRequest: (state, action: PayloadAction<SimulationRequestType | null | undefined>) => {
      return { ...state, simulationReq: action.payload }
    }
  }
})

export const getIsAccountVerified = (state: RootState) => state.app.isAccountVerified
export const getProfileKey = (state: RootState) => state.app.profileKey
export const getContextKey = (state: RootState) => state.app.contextKey
export const getSimulationReq = (state: RootState) => state.app.simulationReq
export const isSimulation = (state: RootState) => !!state.app.simulationReq
export const { SetAccountVerifiedStatus, SetProfileContextKey, SetSimulationRequest } = Slice.actions
export default Slice.reducer
