import { createTheme } from '@mui/material/styles'

const rcmBlueTheme = createTheme({
  palette: {
    primary: {
      main: '#08262C',
      dark: '#080D0E',
      light: '#2A565B',
      bg: '#E4E5E5'
    },
    secondary: {
      main: '#806A4D',
      dark: '#8195A6',
      light: '#BBA984',
      bg: 'rgba(184, 213, 237, 0.08)'
    },
    special: {
      main: '#CBE0F0',
      dark: '#8E9DA8',
      light: '#DBE9F5',
      bg: 'rgba(203, 224, 240, 0.08)'
    },
    warning: {
      main: '#EBC234'
    },
    colors: {
      charcoal: '#3A3A3A',
      softwhite: '#F3F1EC',
      blue1: '#1E4048',
      blue2: '#0E687B',
      lightBlue: '#007680',
      darkGreen: '#2F4134',
      sb2: '#4C9DA8',
      sm1: '#D24F64',
      gray: '#5F676F',
      teal2: '#07807B',
      buttonDisable: '#B1B1B1',
      buttonHover: '#398189',
      lightStroke: '#E6E6E6',
      darkStroke: '#BDBDBD',
      red: '#944325',
      darkRed: '#6B2D1A',
      bodyBackgroundColor: 'white',
      chartIndicatorGreen: '#CCDFB5',
      chartIndicatorBlue: '#1D679D',
      chartIndicatorTeal: '#5DA3AF',
      chartIndicatorTeal2: '#009A95',
      chartIndicatorLightBlue: '#B3E3F4',
      chartIndicatorGreenThumb: '#C4E1B2',
      chartIndicatorVerdigris: '#45BEAA',
      chartIndicatorDarkSkyBlue: '#3E8DDD',
      chartIndicatorVibrant: '#008000',
      chartToolTipBackground: '#D6EFFF',
      basicDgHeaderFooter: '#EFF3F6',
      iconColor: '#2A769D',
      iconBgColor: '#E4F2F7',
      divider: '#6A6A6A',
      smallText: '#707070',
      negativeRed: '#A90505',
      positiveGreen: '#008000',
      toogleSelected: '#40504E',
      toogleSelectedBg: '#E8EEF1',
      toogleBackground: '#FAFAFA',
      accordionSummaryBackground: '#F3F7FA',
      accordionBorderColor: '#719FC8',
      accountAccordionBackground: '#F3F7FA',
      accountAccordionSummaryBackground: '#EDF8FF',
      accountSelectorName: '#676767',
      applyButtonColor: '#E4EFF7',
      applyButtonHover: '#B4C6D4',
      utilityColor1: '#D9D9D9',
      negativeColor: '#AE1414',
      selectMenuLabelColor: '#5E5E5F',
      selectMenuSelectedColor: '#050607',
      selectAccountColor: '#6FA3C5',
      selectIconColor: '#307AA0',
      detailDgHeaderColor: '#F9FBFC',
      detailDgMasterRowColor: '#EAF6FC',
      investmentsGridSymbolColor: '#377EA3',
      investmentGridAccountsRowBorderColor: '#E6E6E6',
      investmentSummarySelectButtonColor: '#61646B',
      investmentSummaryBarChartGradientStartColor: '#4e78c6',
      investmentSummaryBarChartGradientEndColor: '#9cd6de',
      incomeBtnSwitchColor: '#B0D6F1',
      incomeIntrestBarColor: '#88BC75',
      incomeDividendBarColor: '#1D679D',
      popperAlternateRowColor: '#F6F6F6',
      expandWidgetIconColor: '#6FA3C4',
      transfersMenuOptionsBgColor: '#F8F8F8',
      transfersMenuBorderColor: '#EFEFEF',
      toogleSwitchUnselectedTextColor: '#969798',
      toogleSwitchSelectedTextColor: '#13242B',
      transferConfirmationCheckIconColor: '#00CE7D',
      disabledIconColor: '#676767',
      TradeConfirmationsL2GridHeaderBackground: '#EBEBEB',
      paymentWarningIconColor: '#F97226',
      paymentAbaValidationColor: '#2AC8B5',
      balanceWidgetRowCategoryColor: '#5C666F',
      advisorTeamSubHeadColor: '#515760',
      fileDragDropSectionBgColor: '#F6FBFF',
      datePickerHeaderBackGround: '#152429',
      datePickerCalenderBorderColor: '#F0F0F0',
      gainlossWidgetRowCategoryColor: '#5C666F',
      uploadFileSizeExceedWarningColor: '#E55541',
      seperator: '#000000',
      eDeliveryToogleSecondary: '#57676F',
      preferencesTableHeaderColor: 'rgba(19, 39, 43, 0.08)',
      accountHubButtonColor: '#146D9F',
      accountHubEditFormColor: '#F2FAFF',
      accountHubLabelColor: '#4B4B4B',
      accountHubDetailColor: '#B7D5ED',
      areaChartLineColor: '#84B1DB',
      areaChartGradientDarkColor: 'rgba(132, 177, 219, 0.90)',
      areaChartGradientLightColor: 'rgba(248, 248, 255, 0)',
      lifestyleOptionBorderColor: '#99C9D9',
      borderColor: '#D3D3D3',
      customizeMuenBGColor: '#DFF1FF'
    }
  },
  typography: {
    fontFamily: ['Inter Variable'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightMediumBold: 600,
    fontWeightBold: 700,
    fontSize: 15,
    h1: {
      fontFamily: 'Inter Variable',
      fontSize: '1.3125rem',
      fontWeight: 700,
      lineHeight: '125%'
    },
    h2: {
      fontFamily: 'Inter Variable',
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '125%'
    },
    h3: {
      fontFamily: 'Inter Variable',
      fontSize: '1.125rem',
      fontWeight: 700,
      lineHeight: '125%'
    },
    h4: {
      fontFamily: 'Inter Variable',
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '125%'
    },
    h5: {
      fontFamily: 'Inter Variable',
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '125%'
    },
    h6: {
      fontFamily: 'Inter Variable',
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '125%'
    },
    h7: {
      fontFamily: 'Inter Variable',
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '125%'
    },
    body1: {
      fontFamily: 'Inter Variable',
      fontSize: '1rem',
      lineHeight: '166%',
      fontWeight: 400
    },
    body2: {
      fontFamily: 'Inter Variable',
      fontSize: '0.875rem',
      lineHeight: '166%',
      fontWeight: 500
    },
    body4: {
      fontFamily: 'Inter Variable',
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '166%'
    },
    body3: {
      fontFamily: 'Inter Variable',
      fontSize: '0.875rem',
      lineHeight: '150%',
      fontWeight: 400
    },
    utility1: {
      fontFamily: 'Inter Variable',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '166%'
    },
    utility2: {
      fontFamily: 'Inter Variable',
      fontWeight: 700,
      fontSize: '0.75rem',
      lineHeight: '166%'
    },
    utility3: {
      fontFamily: 'Inter Variable',
      fontWeight: 500,
      fontSize: '0.75rem',
      lineHeight: '166%'
    },
    utility4: {
      fontFamily: 'Inter Variable',
      fontWeight: 500,
      fontSize: '0.8125rem',
      lineHeight: '1.5rem'
    },
    utility5: {
      fontFamily: 'Inter Variable',
      fontWeight: 400,
      fontSize: '0.8125rem',
      lineHeight: '1.5rem'
    },
    utility6: {
      fontFamily: 'Inter Variable',
      fontWeight: 700,
      fontSize: '0.6875rem',
      lineHeight: '0.875rem'
    },
    utility7: {
      fontFamily: 'Inter Variable',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '125%'
    },
    utility8: {
      fontFamily: 'Inter Variable',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '125%'
    },
    utility9: {
      fontFamily: 'Inter Variable',
      fontSize: '0.625rem',
      fontWeight: 500,
      lineHeight: '20%'
    },
    utility10: {
      fontFamily: 'Inter Variable',
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '125%'
    },
    utility11: {
      fontFamily: 'Inter Variable',
      fontWeight: 600,
      fontSize: '0.8125rem',
      lineHeight: '1.5rem'
    },
    utility12: {
      fontFamily: 'Inter Variable',
      fontWeight: 300,
      fontSize: '0.75rem',
      lineHeight: '166%'
    },
    utility13: {
      fontFamily: 'Inter Variable',
      fontWeight: 600,
      fontSize: '0.75rem',
      lineHeight: '125%'
    },
    utility14: {
      fontFamily: 'Inter Variable',
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHeight: '125%'
    },
    utility15: {
      fontFamily: 'Inter Variable',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '125%'
    },
    smallText: {
      fontFamily: 'Inter Variable',
      fontSize: '0.75rem',
      lineHeight: '1.0625rem',
      color: '#707070'
    },
    button: {
      fontFamily: 'Inter Variable',
      fontSize: '0.875rem',
      textTransform: 'none',
      fontWeight: 400
    },
    grid: {
      border: 0,
      marginLeft: '0.3125rem',
      marginRight: '0.3125rem'
    },
    gridColumnHeader: {
      fontFamily: 'Inter Variable',
      fontSize: '0.75rem',
      lineHeight: '1rem',
      letterSpacing: '0.1px',
      fontWeight: 700
    },
    gridRow: {
      fontFamily: 'Inter Variable',
      fontSize: '0.75rem',
      lineHeight: '2.0625rem',
      letterSpacing: '0.010625rem',
      fontWeight: 500,
      maxHeight: 'none !important'
    },
    gridCellData: {
      maxHeight: 'none !important',
      fontFamily: 'Inter Variable',
      fontSize: '0.875rem',
      letterSpacing: '0.00625rem',
      fontWeight: 500,
      outline: 'none',
      color: '#3A3A3A'
    },
    gridCellData2: {
      maxHeight: 'none !important',
      fontFamily: 'Inter Variable',
      fontSize: '0.75rem',
      fontWeight: 700,
      lineHeight: '0.959375rem',
      letterSpacing: '0.00625rem',
      outline: 'none',
      color: 'black'
    },
    gridFooterContainer: {
      minHeight: 35,
      paddingLeft: '0.625rem'
    },
    gridContainer: {
      minHeight: '3.125rem'
    },
    dataGridWrapper: {
      minHeight: '3.125rem',
      width: '100%'
    },
    gridTotalRowForSM: {
      minHeight: '3.125rem',
      alignItems: 'center',
      padding: '0.5rem'
    },
    gridMinHeight50: {
      minHeight: '3.125rem'
    },
    iconButtonRoot: {
      fontSize: '0.875rem'
    },
    gridBoxContainer: {
      minHeight: 100,
      marginTop: '0.3125rem',
      width: '100%'
    },
    documentFilterContainer: {
      position: 'sticky',
      zIndex: 101,
      background: 'white',
      marginTop: '0.3125rem'
    },
    downloadPopupContainer: {
      paddingLeft: '1rem',
      paddingRight: '1rem'
    },
    backNextBtn: {
      width: '9.375rem'
    },
    linearProgress: {
      height: '0.125rem'
    },
    conatinerWidth150: {
      width: '9.375rem'
    },
    conatinerWidth500: {
      width: '31.25rem'
    },
    summaryGridRow: {
      minHeight: '1.875rem',
      alignItems: 'center',
      padding: '0.5rem'
    },
    expandCollapseBtn: {
      fontFamily: 'Inter Variable',
      fontSize: '0.8125rem',
      fontWeight: 500,
      lineHeight: '1rem'
    },
    searchFieldConatiner: {
      marginTop: '0.513rem',
      marginBottom: '0.513rem'
    },
    utilityIcon6: {
      fontSize: '0.375rem'
    },
    utilityIcon8: {
      fontSize: '0.5rem'
    },
    utilityIcon15: {
      fontSize: '0.9375rem'
    },
    utilityIcon16: {
      fontSize: '1rem'
    },
    utilityIcon20: {
      fontSize: '1.25rem'
    },
    utilityIcon32: {
      fontSize: '2rem'
    },
    utilityIcon40: {
      fontSize: '2.5rem'
    },
    utilityIcon42: {
      fontSize: '2.625rem'
    },
    utilityIcon48: {
      fontSize: '3rem'
    },
    utilityIcon55: {
      fontSize: '3.4375rem'
    },
    utilityIcon60: {
      fontSize: '3.75rem'
    },
    utilityIcon64: {
      fontSize: '4rem'
    },
    utilityIcon92: {
      fontSize: '5.75rem'
    },
    avatar48: {
      height: '3rem',
      width: '3rem'
    },
    avatar26: {
      height: '1.625rem',
      width: '1.625rem'
    },
    cancelContinueBtn: {
      width: '9.375rem',
      height: ' 2.25rem',
      borderRadius: '0'
    },
    rcmSelectLabel: {
      fontFamily: 'Inter Variable',
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
      letterSpacing: '0.1px',
      fontWeight: 500
    },
    rcmInputLabel: {
      fontSize: '0.8125rem',
      fontFamily: 'Inter Variable',
      lineHeight: ' 1.25rem',
      fontWeight: 500,
      letterSpacing: 0.4
    },
    rcmBtn13: {
      fontSize: '0.8125rem'
    },
    edeliveryHeaderStyle: {
      fontSize: '0.8125rem',
      fontWeight: 500,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'nowrap',
      flexShrink: 0
    },
    edeliveryHeaderWrapper: {
      paddingLeft: '8px',
      paddingRight: '8px',
      position: 'sticky',
      top: 0,
      zIndex: 10
    },
    tabItems: {
      textTransform: 'unset',
      letterSpacing: '0.6px',
      fontWeight: 400,
      fontFamily: 'Inter Variable',
      fontSize: ' 0.875rem',
      lineHeight: '1rem',
      fontStyle: 'normal',
      minWidth: '3.125rem',
      paddingLeft: 0,
      paddingRight: 0
    },
    tabItemsSelected: {
      fontWeight: 700,
      letterSpacing: '0.6px'
    },
    tabMenuStyle: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '1rem',
      letterSpacing: '0.6px'
    },
    rcmDetailstabItems: {
      textTransform: 'unset',
      letterSpacing: '0.17px',
      fontWeight: 500,
      fontFamily: 'Inter Variable',
      fontSize: '0.8125rem',
      lineHeight: '1rem',
      fontStyle: 'normal'
    },
    fiLoginContentContainer: {
      width: '18.75rem',
      height: '3.125rem',
      justifyContent: 'center',
      alignItems: 'center'
    },
    fiImage: {
      margin: 5,
      maxWidth: '15.625rem',
      height: '2.1875rem',
      flex: 1
    },
    fiSelectedFiWrapper: {
      height: '2.5rem',
      maxWidth: '15.625rem',
      justifyContent: 'center',
      alignItems: 'center'
    },
    fontSize12: '0.75rem',
    fontSize13: '0.8125rem',
    fontSize14: '0.875rem',
    fontSize17: '1.0625rem',
    fontSize18: '1.125rem',
    fontSize24: '1.5rem',
    fontSize26: '1.625rem',
    secondaryBtn: {
      fontSize: '0.85rem',
      textTransform: 'inherit',
      letterSpacing: '0.05rem'
    },
    rcmSelectBtn: {
      fontSize: '0.75rem',
      fontWeight: 500
    },
    detailedInfoSection: {
      marginTop: '2.5rem',
      fontSize: '1.25rem'
    }
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'white',
          color: '#3A3A3A',
          border: `1px solid #BBA984`
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'white'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: '#E6E6E6'
          }
        }
      }
    }
  }
})

//rcmBlueTheme = responsiveFontSizes(rcmBlueTheme)
export default rcmBlueTheme
