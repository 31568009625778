import { combineReducers } from '@reduxjs/toolkit'
import TaxDocumentsReducer from '../features/taxdocuments/store/TaxDocumentsSlice'
import StatementsReducer from '../features/statements/store/StatementsSlice'
import CorrespondenceReducer from '../features/correspondence/store/CorrespondenceSlice'
import TrustStatementsReducer from '../features/truststatements/store/TrustStatementsSlice'
import TradeConfirmationsReducer from '../features/tradeconfirmations/store/TradeConfirmationsSlice'
import PerformanceReportsReducer from '../features/performancereports/store/PerformanceReportsSlice'
import DocumentReducer from './DocumentSlice'
const DocumentsReducer = combineReducers({
  taxDocuments: TaxDocumentsReducer,
  statements: StatementsReducer,
  correspondence: CorrespondenceReducer,
  trustStatements: TrustStatementsReducer,
  tradeConfirmations: TradeConfirmationsReducer,
  performanceReports: PerformanceReportsReducer,
  document: DocumentReducer
})

export default DocumentsReducer
