import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../store'

interface IRegistrationSliceState {
  registrationKey?: string
  email?: string
  phoneid?: string
}

const initialState: IRegistrationSliceState = {}

const Slice = createSlice({
  name: '@module/registration',
  initialState,
  reducers: {
    SetRegistrationKey: (state, action: PayloadAction<string | undefined>) => {
      return { ...state, registrationKey: action.payload }
    }
  }
})

export const { SetRegistrationKey } = Slice.actions
export const getRegistrationKey = (state: RootState) => state.modules.registration.registrationKey
export default Slice.reducer
