import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import {
  GroupedAccount,
  Groups,
  IProfile,
  ReplaceExternalAccountsRequest,
  TotalnetworthAccounts
} from '../api/profile.types'
import { ITemplate } from '../api/template.types'
import { RemoveAccount, RemoveAccounts, UpdateNickName } from './types'
import {
  AccountRemoveFromProfile,
  AccountsRemoveFromProfile,
  GetUpdatedTotals,
  UpdateGroupNameInProfile,
  UpdateNickNameInProfile
} from './Utility'
import { ReplaceManualAccountsRequest } from '../../accounts/otherassetsandliabilities/api/types'

interface IHomeSliceState {
  profile?: IProfile
  template?: ITemplate
}

const initialState: IHomeSliceState = {}

const Slice = createSlice({
  name: '@module/home',
  initialState,
  reducers: {
    SetUserNfsProfile: (state, action: PayloadAction<IProfile>) => {
      return { ...state, profile: action.payload }
    },
    SetUserTemplate: (state, action: PayloadAction<ITemplate>) => {
      return { ...state, template: action.payload }
    },
    SetNickNameInProfile: (state, action: PayloadAction<UpdateNickName>) => {
      const profile = state.profile
      const { key, value } = action.payload
      if (profile) {
        const updatedProfile = UpdateNickNameInProfile(profile, key, value)
        return { ...state, profile: { ...updatedProfile } }
      }
      return { ...state }
    },
    RemoveAccountFromProfile: (state, action: PayloadAction<RemoveAccount>) => {
      const profile = state.profile
      const { key, source } = action.payload

      if (profile) {
        const updatedProfile = AccountRemoveFromProfile(profile, key, source)
        return { ...state, profile: { ...updatedProfile } }
      }
      return { ...state }
    },
    RemoveAccountsFromProfile: (state, action: PayloadAction<RemoveAccounts>) => {
      const profile = state.profile
      const { keys, source } = action.payload

      if (profile) {
        const updatedProfile = AccountsRemoveFromProfile(profile, keys, source)
        return { ...state, profile: { ...updatedProfile } }
      }
      return { ...state }
    },

    ReplaceGroupAccountsInProfile: (state, action: PayloadAction<GroupedAccount[]>) => {
      const profile = state.profile
      const groups = state.profile?.groups
      if (profile) {
        const updatedGroups: Groups = { ...groups, customgroups: action.payload }
        const updatedProfile: IProfile = { ...profile, groups: { ...updatedGroups } }
        return { ...state, profile: { ...updatedProfile } }
      }
      return { ...state }
    },
    ReplaceDefaultGroupAccountsInProfile: (state, action: PayloadAction<GroupedAccount[]>) => {
      const profile = state.profile
      const groups = state.profile?.groups
      if (profile) {
        const updatedGroups: Groups = { ...groups, defaultgroups: action.payload }
        const updatedProfile: IProfile = { ...profile, groups: { ...updatedGroups } }
        return { ...state, profile: { ...updatedProfile } }
      }
      return { ...state }
    },
    ReplaceManualAccountsInProfile: (state, action: PayloadAction<ReplaceManualAccountsRequest>) => {
      const profile = state.profile
      if (profile) {
        const totalaccounts =
          action.payload.mode === 'add' ? GetUpdatedTotals('manual', profile, 'add') : profile.totalaccounts
        const updatedProfile: IProfile = {
          ...profile,
          totalnetworthaccounts: action.payload.otherAssetsAddResponse.totalnetworthaccounts,
          groups: action.payload.otherAssetsAddResponse.groups,
          totalaccounts: totalaccounts
        }
        return { ...state, profile: { ...updatedProfile } }
      }
      return { ...state }
    },
    ReplaceExternalAccountsInProfile: (state, action: PayloadAction<ReplaceExternalAccountsRequest>) => {
      const profile = state.profile
      if (profile) {
        const updatedProfile: IProfile = {
          ...profile,
          totalnetworthaccounts: action.payload.externalAccountAddResponse.totalnetworthaccounts,
          groups: action.payload.externalAccountAddResponse.groups,
          totalaccounts: GetUpdatedTotals('external', profile, 'add', action.payload.noOfAccountsAdded)
        }
        return { ...state, profile: { ...updatedProfile } }
      }
      return { ...state }
    },
    ReplaceTotalNetworthAccountsInProfile: (state, action: PayloadAction<TotalnetworthAccounts[]>) => {
      const profile = state.profile
      if (profile) {
        const updatedProfile: IProfile = {
          ...profile,
          totalnetworthaccounts: action.payload
        }
        return { ...state, profile: { ...updatedProfile } }
      }
      return { ...state }
    },
    ReplaceGroupsInProfile: (state, action: PayloadAction<Groups>) => {
      const profile = state.profile
      if (profile) {
        const updatedProfile: IProfile = { ...profile, groups: action.payload }
        return { ...state, profile: { ...updatedProfile } }
      }
      return { ...state }
    },
    SetGroupNameInProfile: (state, action: PayloadAction<UpdateNickName>) => {
      const profile = state.profile
      const { key, value } = action.payload
      if (profile) {
        const updatedProfile = UpdateGroupNameInProfile(profile, key, value)
        return { ...state, profile: { ...updatedProfile } }
      }
      return { ...state }
    }
  }
})

export const {
  SetUserNfsProfile,
  SetUserTemplate,
  SetNickNameInProfile,
  RemoveAccountFromProfile,
  RemoveAccountsFromProfile,
  ReplaceGroupAccountsInProfile,
  ReplaceDefaultGroupAccountsInProfile,
  ReplaceManualAccountsInProfile,
  ReplaceExternalAccountsInProfile,
  ReplaceTotalNetworthAccountsInProfile,
  SetGroupNameInProfile
} = Slice.actions
export const getUserNfsProfile = (state: RootState) => state.modules.home.profile
export const getUserTemplate = (state: RootState) => state.modules.home.template
export default Slice.reducer
